import React from "react";
import { makeCardGridItems, LeadDetails } from "@leadId/index";
import { createLeadView, ViewComponent } from "@leadId/view";
import {
    IdVerification,
} from "@leadId/data/attributes";
import { useLeadView } from "@api/leads";
import { LeadContent } from "./_lib";

export const Cards = makeCardGridItems<any, LeadDetails>([
    IdVerification,
]);

const IdVerificationTab: ViewComponent = (props) => {
    const { lead, loading, error } = useLeadView(props.leadId, "");

    return (
        <LeadContent
            cards={Cards}
            lead={lead}
            loading={loading}
        />
    );
};

export default createLeadView(IdVerificationTab, {});